.rules-list {
    max-width: 70%;
    margin: 0 auto;

    h4 {
        margin-bottom: 15px;
    }

    ul {
        list-style: none;
        margin: 0;
    }

    li {
        font-size: 1.5em;
        margin-bottom: 15px;
    }
}