.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: calc(10px + 2vmin);
  color: white;

  p {
    margin: 20px 20px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }

  .logo-suck {
    color: orange;
  }

  .logo-it {
    color:red;
  }

  .logo-dot {
    color:black;
    font-weight: bold;
  }

  .logo-bingo {
    color:firebrick;
  }
}

.App-link {
  color: #61dafb;
}

.start-quiz {
  display: none;
}

@media only screen and (min-width: 800px) {
  .start-quiz {
    display: block;
  }
  .start-quiz-mobile {
    display: none;
  }
}

.app-initial-state {
  h1 {
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn; 
    animation-duration: 1.5s;   
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-in-out; 
    -webkit-animation-timing-function: ease-in-out;     
    visibility: visible !important; 
    color: red;
    // animation-delay: 2s;
    // -webkit-animation-delay: 2s;
  }
}

@keyframes fadeIn {
  0% {
      transform: scale(0);
      opacity: 0.0;       
  }   
  100% {
      transform: scale(1);
      opacity: 1; 
  }       
}