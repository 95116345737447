.answer-result {
    border-radius: 10px;
    border: 3px solid red;
    position: absolute;
    top: 20%;
    bottom: 40%;
    left: 20%;
    right: 20%;
    padding: 20px;
    z-index: 10;
}

.answer-result-correct {
    background-color: yellow;
}

.answer-result-incorrect {
    background-color: firebrick;
}

.answer-result-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
}