.question-option {
    padding: 10px 20px;
    margin: 30px auto 0;
    border: 1px solid #888;
    border-radius: 10px;
    background: rgba(0, 0, 0, .3);
    font-size: 2em;
    text-align: left;
    color: white;

    &:hover {
        cursor: pointer;
        background: rgba(221, 239, 101, 0.5);
    }    
    span {
        font-weight: bold;
    }
}