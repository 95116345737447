body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
  position: relative;
  min-height: 100vh;
}

body::before{
  content: "";
  filter: blur(6px);
  opacity: 0.6;
  background: url('../public/tapestry.JPG');
  background-repeat: repeat;
  background-position: center;
  height: 100%;
  width: 100vw;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  padding: 20px;
  font-size: 2em;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: rgba(221, 239, 101, 0.5);
  }
}

h1 {
  font-size: 120px;
  margin: 30px auto;
}

h4 {
  font-size: 1.3em;
}

h3 {
  font-size: 3em;
}

@media only screen and (min-width: 800px) {
  h1 {
    font-size: 170px;
    margin: 80px;
  }

  h4 {
    font-size: 2em;
  }
}
