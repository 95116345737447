$bar-height: 55px;

div.points-bar {
    background-color: yellow;
    width: 100%;
    height: $bar-height;
    margin-bottom: 15px;
    position: relative;

    h3 {
        margin: 0;
        line-height: $bar-height - 20px;
        position: absolute;
        left: 0;
        z-index: 4;
        margin: 10px;
    }

    .points-div {
        min-width: 2%;
        max-width: 100%;
        height: $bar-height;
        background-color: firebrick;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
    }

    .marker-div {
        height: $bar-height;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        border-right: 4px solid orange;
        box-sizing: border-box;

        p {
            position: absolute;
            right: 2px;
            line-height: $bar-height;
            margin:0;
        }
    }
}